import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Split Jerk 6-6-6\\@70% 1RM`}</p>
    <p>{`Band Pull Aparts 12-12-12`}</p>
    <p>{`then,  3 Rounds For Time Of:`}</p>
    <p>{`21-Push Jerks (115/75)`}</p>
    <p>{`15-K2E’s`}</p>
    <p>{`9-Bar Facing Burpees`}</p>
    <p>{`*`}{`*`}{`Bonus`}{`*`}{`*`}</p>
    <p>{`21-15-9 each:`}</p>
    <p>{`Calorie Assault Bike`}</p>
    <p>{`V Ups`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Get your 4 person (2 men/2 women) teams together now for True Grit
6!  RX and Scaled teams will battle it out for the 6th year at the Ville
for cash prizes and other goodies.  September 15th.  Register by
September 1st to ensure you get your t shirt on the day of the
competition.  If you’d like to compete but don’t have a team we have a
signup sheet by the white board.  If you’d like to help judge please add
your name to the sheet.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/true-grit-6-tickets-48356197689"
          }}>{`https://www.eventbrite.com/e/true-grit-6-tickets-48356197689`}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more info go to: truegritcomp.wordpress.com`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start this Sunday.  Email Eric at
Fallscitystrength\\@gmail for more info. `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      